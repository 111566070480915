@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Dm Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  background-color: #222222;
}

body {
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}
