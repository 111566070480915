@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Dm Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  background-color: #222222;
}

body {
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.App_container__6j1zw {
  /* display: inline-block; */
  /* background: linear-gradient(-45deg, #2E2D4E, #b43485, #003552, #233A43, #104C56); */
  background: #1b1d24;
  -webkit-animation: App_gradient__1XVXu 10s ease infinite;
          animation: App_gradient__1XVXu 10s ease infinite;
  background-size: 400% 400%;
  background-repeat: repeat;
  background-attachment: fixed;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  /* padding: 16px; */
  margin: 0;
  /* background-color:black; */
}

body {
  background: transparent;
}

@-webkit-keyframes App_gradient__1XVXu {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes App_gradient__1XVXu {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Simple_container__2sFl5 {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  padding: 24px;
  grid-gap: 24px;
  gap: 24px;
  -webkit-animation: Simple_fadein__2M_lo 2s;
          animation: Simple_fadein__2M_lo 2s;
}

@-webkit-keyframes Simple_fadein__2M_lo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Simple_fadein__2M_lo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Simple_left__3ZoKT {
  width: 318px;
  box-sizing: border-box;
}

.Simple_portrait__2hBVy {
  margin-bottom: 20px;
  height: 306px;
  width: 306px;
  float: right;
  border-radius: 20px;
  border: 1px solid #FCF6F5;
  /* box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.274); */
  object-fit: cover;
  object-position: 0 75%;
}

.Simple_contact__36yWC {
  width: 174px;
  float: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.Simple_media__fBlhb {
  display: -webkit-flex;
  display: flex;
}

.Simple_media__fBlhb > a > button {
  padding: 2px 10px;
  /* height: 32px; */
  border-radius: 24px;
  border: 1px solid #FCF6F5;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #FCF6F5;
  cursor: pointer;
  transition: 0.3s;
}

.Simple_media__fBlhb > a > button:hover {
  background-color: #FCF6F5;
  color: #0D101B;
  /* box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.274); */
}

.Simple_content__3xpzH {
  width: 460px;
  /* text-shadow: 2px 2px 5px black; */
}

.Simple_media__fBlhb > a {
  color: #FCF6F5;
  margin-left: 12px;
}

.Simple_title__2K-bP {
  margin-bottom: 16px;
}

.Simple_title__2K-bP > p {
  font-size: 40px;
  font-style: normal;
}

.Simple_title__2K-bP > p > a {
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  background-image: linear-gradient(60deg, #007EFF, #009FFD, #00B6F6, #00E3E2);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}

.Simple_description__1lpT1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FCF6F5;
}

.Simple_description__1lpT1 > a {
  color: #FCF6F5;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 886px) {
  .Simple_container__2sFl5 {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
    height: auto;
    -webkit-align-items: center;
            align-items: center;
  }

  .Simple_left__3ZoKT {
    text-align: center;
  }

  .Simple_portrait__2hBVy {
    margin: auto auto 20px auto;
    float: none;
  }

  .Simple_contact__36yWC {
    margin: auto;
    float: none;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .Simple_content__3xpzH {
    margin-bottom: 24px;
    text-align: center;
  }

  .Simple_media__fBlhb > a {
    margin: 0 6px;
  }

  .Simple_title__2K-bP {
    font-size: 28px;
  }

  .Simple_description__1lpT1 {
    font-size: 18px;
  }
}

