.container {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
  gap: 24px;
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.left {
  width: 318px;
  box-sizing: border-box;
}

.portrait {
  margin-bottom: 20px;
  height: 306px;
  width: 306px;
  float: right;
  border-radius: 20px;
  border: 1px solid #FCF6F5;
  /* box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.274); */
  object-fit: cover;
  object-position: 0 75%;
}

.contact {
  width: 174px;
  float: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.media {
  display: flex;
}

.media > a > button {
  padding: 2px 10px;
  /* height: 32px; */
  border-radius: 24px;
  border: 1px solid #FCF6F5;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #FCF6F5;
  cursor: pointer;
  transition: 0.3s;
}

.media > a > button:hover {
  background-color: #FCF6F5;
  color: #0D101B;
  /* box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.274); */
}

.content {
  width: 460px;
  /* text-shadow: 2px 2px 5px black; */
}

.media > a {
  color: #FCF6F5;
  margin-left: 12px;
}

.title {
  margin-bottom: 16px;
}

.title > p {
  font-size: 40px;
  font-style: normal;
}

.title > p > a {
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  background-image: linear-gradient(60deg, #007EFF, #009FFD, #00B6F6, #00E3E2);
  background-clip: text;
  color: transparent;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FCF6F5;
}

.description > a {
  color: #FCF6F5;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 886px) {
  .container {
    flex-wrap: wrap-reverse;
    height: auto;
    align-items: center;
  }

  .left {
    text-align: center;
  }

  .portrait {
    margin: auto auto 20px auto;
    float: none;
  }

  .contact {
    margin: auto;
    float: none;
    justify-content: center;
  }

  .content {
    margin-bottom: 24px;
    text-align: center;
  }

  .media > a {
    margin: 0 6px;
  }

  .title {
    font-size: 28px;
  }

  .description {
    font-size: 18px;
  }
}
