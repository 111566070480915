.container {
  /* display: inline-block; */
  /* background: linear-gradient(-45deg, #2E2D4E, #b43485, #003552, #233A43, #104C56); */
  background: #1b1d24;
  animation: gradient 10s ease infinite;
  background-size: 400% 400%;
  background-repeat: repeat;
  background-attachment: fixed;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  /* padding: 16px; */
  margin: 0;
  /* background-color:black; */
}

body {
  background: transparent;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
